export default [
  {
    name : "Profissionais",
    route: "buscapsicologo",
    image : "/assets/images/menu-item-1.png",
    alt : "",
    link: "/dash/busca-psicologo"
  },
  {
    name : "Minhas consultas",
    route: "consultas",
    image : "/assets/images/menu-item-2.png",
    alt : "",
    link: "/dash/minhas-consultas"
  },
  {
    name : "Agenda",
    route: "agenda",
    image : "/assets/images/menu-item-3.png",
    alt : "",
    link: "/dash/agenda"
  },
  {
    name : "Minha conta",
    route: "minhaconta",
    image : "/assets/images/menu-item-4.png",
    alt : "",
    link: "/dash/minha-conta"
  },
  {
    name : "Sair",
    image : "/assets/images/menu-item-5.png",
    alt : "",
    link: "/dash/logout"
  }
]
