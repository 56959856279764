export default [
  {
    name : "Agenda",
    route: "agenda",
    image : "/assets/images/menu-item-3.png",
    alt : "agenda",
    link: "/dash/agenda"
  },
  {
    name : "Consultas",
    route: "consultas",
    image : "/assets/images/menu-item-2.png",
    alt : "",
    link: "/dash/minhas-consultas"
  },
  {
    name : "Solicitações",
    route: "solicitacoes",
    image : "/assets/images/menu-item-6.svg",
    alt : "",
    link: "/dash/solicitacoes"
  },
  {
    name : "Meu perfil",
    route: "perfil",
    image : "/assets/images/menu-item-4.png",
    alt : "",
    link: "/dash/perfil/"
  },
  {
    name : "Sair",
    image : "/assets/images/menu-item-5.png",
    alt : "",
    link: "/dash/logout"
  }
]
