












import { Vue, Prop, Component } from "vue-property-decorator";

@Component({})
export default class VerticalMenuItem extends Vue {
  @Prop() text?: string;
  @Prop() link?: string;
  @Prop() route?: string;
  @Prop({ default: "" }) imgAlt?: string;
  @Prop({ default: "" }) imgSrc?: string;

}
