




















import { Vue, Component } from "vue-property-decorator";
import { MenuIcon } from "vue-feather-icons";

import VerticalMenu from "../../../components/VerticalMenu/VerticalMenu.vue"

@Component({
  components: {
    VerticalMenu,
    MenuIcon,
  },
})
export default class Main extends Vue {
  mobileMenuActive = false;
  windowWidth = window.innerWidth;
  resizeListener: any;

  mounted() {
    this.resizeListener = () => {
      this.windowWidth = window.innerWidth;
    };
    window.addEventListener("resize", this.resizeListener);
  }

  destroyed() {
    if (this.resizeListener) {
      window.removeEventListener("resize", this.resizeListener);
    }
  }

  get menuActive() {
    return this.windowWidth >= 640 || this.mobileMenuActive;
  }

  showMobileMenu() {
    this.mobileMenuActive = true;
  }

  hideMobileMenu() {
    this.mobileMenuActive = false;
  }
}
