











import { Vue, Component } from "vue-property-decorator";
import VerticalMenuItem from './VerticalMenuItem.vue'

import vertMenuItemsPatient from './vertMenuItemsPatient.js'
import vertMenuItemsPsychologist from './vertMenuItemsPsychologist.js'

@Component({
  components: {
    VerticalMenuItem,
  },
})
export default class VerticalMenu extends Vue {
  get menuItems() {
    const userRole = this.$store.state.activeUser?.role;
    if (userRole === "PATIENT") {
      return vertMenuItemsPatient;
    } else {
      return vertMenuItemsPsychologist;
    }
  }
}
